$primary-blue: #003595;
$light-blue: #2e5baa;
$dark-blue: #0332f1;
$primary-white: #ffffff;
$primary-light-white: #f5f8fd;
$primary-dark-grey: #e5f5ff33;
$primary-light-grey: #666;
$primary-dark-white: #ccc;
$dimmer-black: #393939;
$secondary-blue: #0432f0;
$light-grey: #4f4f4f;
$off-white: #f6f6f6;
$primary-pink: #ff00ff;
$primary-dark-white: #fff;
$primary-black: #232223;
$dimmer-blue: #3a6bc5;
$primary-light-blue: #afcbfb;
$darker-magenta: #cc00cc;
$light-purple: #af59f0;
$light-green: #00ae44;
$primary-purple:#EDE7F1;
$dark-purple:#956FAE;

$ace-blue: #0332f1;
$ace-light-blue: #f3fbfe;
$secondary-light-blue: #e5f7fd;

$ace-primary-100: #e6ebf4;
$ace-primary-200: #ccd7ea;
$ace-primary-300: #99aed5;
$ace-primary-400: #6686bf;
$ace-neutral-100: #fff;
$ace-neutral-200: #f4f4f4;
$ace-neutral-300: #e9e9e9;
$ace-neutral-400: #d3d3d3;
$ace-neutral-500: #727780;
$ace-neutral-600: #4f4e4f;
$ace-neutral-700: #393939;
$ace-neutral-800: #232323;
$ace-alert-100: #fde6ea;
$ace-alert-500: #e6062a;
$black: #000000;
$ace-light-red-warning: #fff0e7;
$ace-red-border-warning: #ff6b12;
$ace-warning: #ff6b12;
$ace-color-warning-400: #FFA671;
$ace-warning-light: #fff0e7;
$ace-warning-dark: #ffbc94;
$ace-text-black: #4f4f4f;
$ace-neutral-border: #979797;
$ace-success-400: #66ce8f;
$ace-success-100: #e6f7ec;

//fonts
$open-sans: 'Open Sans';
//font-style
$font-normal: normal;

/*Font Weight Variables*/
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

//template widths
$grid-breakpoints: (
  xs: 375px,
  mxs: 390px,
  msm: 576px,
  sm: 768px,
  md-custom: 834px,
  md: 1024px,
  lg: 1280px,
  xl-custom: 1440px,
  xl: 1441px,
);
