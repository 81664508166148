@mixin fontStyle($font-family, $font-style, $font-weight, $font-size, $color) {
  color: $color;
  font-family: $font-family;
  font-size: $font-size;
  font-style: $font-style;
  font-weight: $font-weight;
}
@mixin alcon-button {
  background: $secondary-blue;
  border-radius: 100px;
  border: 2px solid $primary-white;
  color: $white;
  letter-spacing: 0.15px;
  text-decoration: none;
  width: fit-content;
}

@mixin alcon-button-outlined {
  background: $white;
  border: 2px solid $primary-white;
  border-radius: 100px;
  color: $primary-blue;
  letter-spacing: 0.15px;
  text-decoration: none;
  width: fit-content;
}

@mixin alcon-card {
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border: 1px solid $white;
  border-radius: rem(12px);
  box-shadow: 0px rem(5px) rem(20px) rgba(0, 53, 149, 0.15);
  padding: rem(24px);
}

@mixin adi-home-card {
  width: calc(50% - 20px);
  border: 1px solid $secondary-blue;

  @include media-breakpoint-down(xl) {
    width: calc(50% - 20px);
  }

  @include media-breakpoint-down(md-custom) {
    width: 348px;
  }

  @include media-breakpoint-between(md-custom, md) {
    width: 381px;
  }

  @include media-breakpoint-down(sm) {
    width: 343px;
  }

  @include media-breakpoint-down(xs) {
    width: auto;
  }
}
